export const CustomSegmentLabelPosition = {
  Inside: 'INSIDE',
  Outside: 'OUTSIDE',
}

export const Transition = {
  easeLinear: 'easeLinear',
  easeQuadIn: 'easeQuadIn',
  easeQuadOut: 'easeQuadOut',
  easeQuadInOut: 'easeQuadInOut',
  easeCubicIn: 'easeCubicIn',
  easeCubicOut: 'easeCubicOut',
  easeCubicInOut: 'easeCubicInOut',
  easePolyIn: 'easePolyIn',
  easePolyOut: 'easePolyOut',
  easePolyInOut: 'easePolyInOut',
  easeSinIn: 'easeSinIn',
  easeSinOut: 'easeSinOut',
  easeSinInOut: 'easeSinInOut',
  easeExpIn: 'easeExpIn',
  easeExpOut: 'easeExpOut',
  easeExpInOut: 'easeExpInOut',
  easeCircleIn: 'easeCircleIn',
  easeCircleOut: 'easeCircleOut',
  easeCircleInOut: 'easeCircleInOut',
  easeBounceIn: 'easeBounceIn',
  easeBounceOut: 'easeBounceOut',
  easeBounceInOut: 'easeBounceInOut',
  easeBackIn: 'easeBackIn',
  easeBackOut: 'easeBackOut',
  easeBackInOut: 'easeBackInOut',
  easeElasticIn: 'easeElasticIn',
  easeElasticOut: 'easeElasticOut',
  easeElasticInOut: 'easeElasticInOut',
  easeElastic: 'easeElastic',
}
